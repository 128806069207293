<template>
    <div>
        <b-container class="text-center">
            <b-row cols="1" align-v="center">
                <b-col>
                    <b-button variant="primary" @click="refreshDeviceList">Refresh List</b-button>
                </b-col>
            </b-row>
        </b-container>
        <p></p>
        <div class="text-center">
            <b-table
                striped
                bordered
                small
                hover
                head-variant="light"
                selectable
                @row-selected="onRowSelected"
                select-mode="single"
                responsive="sm"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
            >
            </b-table>
            <div>
                <b-container class="border border-info">
                    <b-row cols="2" align-v="center" align-h="center">
                        <b-col>
                            <b-card-text class="mt-1 mb-1">Currently Selected Device:</b-card-text>
                        </b-col>
                        <b-col>
                            <b-form-input class="text-center mt-1 mb-1" v-model="selected.deviceId" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-container>
                <p></p>
            </div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
            ></b-pagination>
            <hr>
        </div>
    </div>
</template>

<script>
export default {
    name: "SimpleDeviceList",
    props: ['id'],
    components: {
    },
    data() {
        return {
            fields: [
                {key: 'deviceId', label: 'ID', sortable: true},
                {key: 'state', label: 'State', formatter: this.stateFormat, sortable: true },
            ],
            stateOptions: [
                {value: 0, text: 'New Device'},
                {value: 1, text: 'Setup Mode'},
                {value: 2, text: 'One Time Auth'},
                {value: 3, text: 'Not Used'},
                {value: 4, text: 'Assigned / Registered'},
            ],
            sortBy: 'deviceId',
            sortDesc: false,
            selected: [{deviceId: null, userId: null}],
            selectedUser: null,
            perPage: 5,
            currentPage: 1,
            totalRows: 1,
            checkOpt: true,
            filter: null,
            filterOn: ['deviceId', 'deviceType', 'userId', 'state']
        }
    },
    created() {
        this.$store.dispatch('getAdminDevList').then(() => {
            this.totalRows = this.$store.getters.adminDevList.length;
        });
        if (this.$store.getters.adminUserList.length === 0) {
            this.$store.dispatch('getAdminUserList');
        }
    },
    methods: {
        onRowSelected(items) {
            this.selected = items[0];
            if (this.$props.id === "OldDevice") {
                this.$store.commit('storeSwapDevOld', this.selected);
            }
            else if (this.$props.id === "NewDevice") {
                this.$store.commit('storeSwapDevNew', this.selected);
            }
            else {
                this.$store.commit('storeAdminSelectedDevice', this.selected);
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        stateFormat(value) {
            return this.stateOptions[value].text;
        },
        refreshDeviceList() {
            this.$store.dispatch('getAdminDevList').then(() => {
                this.totalRows = this.$store.getters.adminDevList.length;
            });
        }
    },
    computed: {
        items() {
            const devListRef = this.$store.getters.adminDevList;
            if (this.$props.id === "OldDevice") {
                let regDevList = [];
                for (let i = 0; i < devListRef.length; i++) {
                    if (devListRef[i].state === 4) {
                        regDevList.push(devListRef[i]);
                    }
                }
                return regDevList;
            }
            else if (this.$props.id === "NewDevice") {
                let newDevList = [];
                for (let i = 0; i < devListRef.length; i++) {
                    if (devListRef[i].state === 0) {
                        newDevList.push(devListRef[i]);
                    }
                }
                return newDevList;
            }
            else {
                return devListRef;
            }
        }
    }
}
</script>

<style scoped>

</style>
