<template>
<div>
    <h3>Replace Device For User</h3>
    <hr>
    <div>
        <b-container class="text-center">
            <b-row
                no-gutters
                cols="1"
                cols-sm="1"
                cols-md="2"
                cols-lg="3"
                align-v="start"
                align-h="center"
            >
                <b-col>
                    <b-card class="mb-1" style="min-width: 18rem">
                        <b-form-group label="User For Replacement:">
                            <b-row cols="2">
                                <b-col>
                                    <b-form-input disabled v-model="selUserName"></b-form-input>
                                </b-col>
                                <b-col>
                                    <b-button @click="selectUser">Select User</b-button>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <hr>
    <b-container>
        <b-row
            no-gutters
            cols="1"
            cols-sm="1"
            cols-md="2"
            cols-lg="2"
            align-v="start"
            align-h="center"
        >
            <div>
                <b-col>
                    <b-card header="Old Device" class="mb-1">
                        <simple-device-list id="OldDevice"></simple-device-list>
                    </b-card>
                </b-col>
            </div>
            <div>
                <b-col>
                    <b-card header="New Device" class="mb-1">
                        <simple-device-list id="NewDevice"></simple-device-list>
                    </b-card>
                </b-col>
            </div>
        </b-row>
    </b-container>
    <hr>
    <div class="text-center">
        <b-button @click="swapDevice" variant="outline-primary">
            <b-icon icon="arrow-left-right"></b-icon> Replace Device
        </b-button>
    </div>
    <div>
        <b-modal
            ref="user-modal"
            hide-footer
            centered
            title="Select User"
        >
            <user-list-simple></user-list-simple>
            <hr>
            <b-container class="text-center">
                <b-row cols="1">
                    <b-col>
                        <b-button variant="success" block @click="userOK">OK</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
    <div>
        <b-modal ref="confirm-swap-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Swap {{oldDevSel}} With {{newDevSel}} For {{userSel}}?</h5>
            </div>
            <hr>
            <div class="text-center">
                <b-checkbox v-model="checkOpt">Delete Old Device</b-checkbox>
            </div>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="swapYes">Yes</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="swapNo">No</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</div>
</template>

<script>
import UserListSimple from "@/components/AdminModules/Users/UserListSimple";
import SimpleDeviceList from "@/components/AdminModules/Devices/SimpleDeviceList";

export default {
    name: "SwapDevices",
    data() {
        return {
            checkOpt: true
        }
    },
    components: {
        UserListSimple,
        SimpleDeviceList
    },
    created() {

    },
    mounted() {

    },
    methods: {
        selectUser() {
            this.$refs['user-modal'].show();
        },
        userOK() {
            this.$refs['user-modal'].hide();
        },
        swapDevice() {
            //console.log("Swap");
            if (this.$store.getters.adminSelectedUser === null) {
                this.$bvToast.toast('Need to Selected a User to Perform Replacement On', {
                    title: 'Replace Device',
                    variant: 'danger',
                    solid: true
                });
                return;
            }
            if (this.$store.getters.swapDevOld === null) {
                this.$bvToast.toast('Need to Select An Old Device to Replace', {
                    title: 'Replace Device',
                    variant: 'danger',
                    solid: true
                });
                return;
            }
            if (this.$store.getters.swapDevNew === null) {
                this.$bvToast.toast('Need to Select An New Device to Replace', {
                    title: 'Replace Device',
                    variant: 'danger',
                    solid: true
                });
                return;
            }
            this.$refs['confirm-swap-modal'].show();
        },
        swapYes() {
            this.$refs['confirm-swap-modal'].hide();
            this.$store.dispatch('swapDeviceUser', {
                oldDevID: this.$store.getters.swapDevOld.deviceId,
                newDevID: this.$store.getters.swapDevNew.deviceId,
                username: this.$store.getters.adminSelectedUser.username,
                remOld: this.checkOpt
            }).then(() => {
                this.$bvToast.toast('Device Replaced For User', {
                    title: 'Replace Device',
                    variant: 'success',
                    solid: true
                });
                setTimeout(() => {
                    this.$router.back();
                }, 1500);
            }).catch(() => {
                this.$bvToast.toast('Error Replacing Device', {
                    title: 'Replace Device',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        swapNo() {
            this.$refs['confirm-swap-modal'].hide();
        }
    },
    computed: {
        selUserName() {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username
            else
                return '';
        },
        oldDevSel() {
            if (this.$store.getters.swapDevOld === null)
                return '';
            else
                return this.$store.getters.swapDevOld.deviceId;
        },
        newDevSel() {
            if (this.$store.getters.swapDevNew === null)
                return '';
            else
                return this.$store.getters.swapDevNew.deviceId;
        },
        userSel() {
            if (this.$store.getters.adminSelectedUser === null)
                return '';
            else
                return this.$store.getters.adminSelectedUser.username;
        }
    }
}
</script>

<style scoped>

</style>
